<template>
  <en-dialog :model-value="modelValue" width="80%" title="盘点单列表" @close="$emit('update:model-value', false)">
    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STCASTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="SCADFD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/taking/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
